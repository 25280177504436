
let serverUrl;

if (process.env.NODE_ENV === 'production') {
  console.log("process env node env é: " + process.env.NODE_ENV + " e react app node env é: " + process.env.REACT_APP_NODE_ENV + " vai usar producao fixa no codigo, mas o backend_url é:" + process.env.REACT_APP_BACKEND_URL);
  serverUrl = "https://backend-juridico.marco.log.br"
  //serverUrl = process.env.REACT_APP_BACKEND_URL; // URL de produção

} else {
  console.log("vai usar LOCALHOST pois react node env é" + process.env.REACT_APP_NODE_ENV + ", mas node env é: " + process.env.NODE_ENV + " e mas o backend_url é:" + process.env.REACT_APP_BACKEND_URL);
  serverUrl = 'http://localhost:3001'; // URL de desenvolvimento
}

export default serverUrl;
