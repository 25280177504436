import React from 'react';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  height: 300px; /* Altura fixa desejada */
  overflow-y: auto; /* Adiciona rolagem vertical quando necessário */
  overflow-x: hidden; /* Esconde a rolagem horizontal */

  /* Estilização da barra de rolagem */
  ::-webkit-scrollbar {
    width: 12px; /* Largura da barra de rolagem */
  }

  /* Estilização do trilho da barra de rolagem */
  ::-webkit-scrollbar-track {
    background-color: #e0f7fa; /* Cor de fundo do trilho */
    border-radius: 10px; /* Borda arredondada do trilho */
  }

  /* Estilização do polegar da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background-color: #81d4fa; /* Cor do polegar */
    border-radius: 10px; /* Borda arredondada */
    border: 3px solid #e0f7fa; /* Borda para separar o polegar do trilho */
  }

  /* Mudar a cor do polegar quando estiver sendo arrastado */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #4fc3f7; /* Cor do polegar ao passar o mouse */
  }
`;





export const ListItem = styled.div`
  background-color: rgba(240, 240, 240, 0.5); /* Cor de fundo quase transparente */
  border: 1px solid #ccc; /* Cor da borda */
  border-radius: 10px; /* Borda curva */
  padding: 10px; /* Espaço interno */
  width: 100%;
  max-width: 500px; /* Limite máximo de largura */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para um efeito agradável */
  word-break: break-word; /* Quebra a palavra para garantir que o texto não transborde */
`;


export const ListComponent = ({ items }) => {
  return (
    <ListContainer>
      {items.map((item, index) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </ListContainer>
  );
};

export default ListComponent;
