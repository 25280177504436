import Logo from '../Logo'
import OpcoesHeader from '../OpcoesHeader'
import IconesHeader from '../IconesHeader'
import styled from 'styled-components'
import { useAuth } from '../../AuthContext';

const HeaderContainer = styled.header`
    background-color: #FFF;
    display: flex;
    justify-content: center;
`

function Header() {
    const { isAuthenticated } = useAuth();

    return(
        <HeaderContainer>
            <Logo/>
            {isAuthenticated && <OpcoesHeader />}
            <IconesHeader/>
        </HeaderContainer>
    )
}

export default Header