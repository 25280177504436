import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Header from '../componentes/Header';
import Cookies from 'js-cookie';
import { useAuth } from '../AuthContext';
import jwt_decode from 'jwt-decode';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { Input} from '../componentes/Input';
import AppContainer from '../componentes/AppContainer';
import { useRef } from 'react';
import {
  InputContainer,
  InputLabel,
} from '../componentes/GridList/gridList'; // Importando os estilos do arquivo styles.js



const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;



const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
`;

// Estilo para o botão de logout
const LogoutButton = styled.button`
    background-color: #EB9B00;
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: lightgreen;
  margin-top: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const LoginPage = () => {
  const { isAuthenticated, setIsAuthenticated, NOMEAdvogado, setNomeAdvogado, IDAdvogado, setIDAdvogado, OABAdvogado, setOABAdvogado, EstadoOABAdvogado, setEstadoOABAdvogado, EmailAdvogado, setEmailAdvogado, AccessLevelAdvogado, setAccessLevelAdvogado } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [novasenha, setnovasenha] = useState('');
  const [error, setError] = useState('');
  const [formNovaSenhaVisible, setformNovaSenhaVisible] = useState('');
  const nomeInputRef = useRef(null);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
  setIsAuthenticated(true);  
  const decodedToken = jwt_decode(token);
  setNomeAdvogado(decodedToken.NOME);
  setIDAdvogado(decodedToken.ID);
  setOABAdvogado(decodedToken.OAB);
  setEstadoOABAdvogado(decodedToken.ESTADO_OAB);
  setEmailAdvogado(decodedToken.EMAIL);
  setAccessLevelAdvogado(decodedToken.ACCESS_LEVEL);
    }
  }, [setIsAuthenticated, setNomeAdvogado, setIDAdvogado, setOABAdvogado, setEstadoOABAdvogado, setEmailAdvogado, setAccessLevelAdvogado]);

  const handleLogout = () => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setIsAuthenticated(false);
    setformNovaSenhaVisible(false);
};

const handleTrocaSenha = () => {
  setformNovaSenhaVisible(true);
};

const handleChangePassword = async () => {
  try {
    // Fazer uma chamada à API para autenticar o usuário
    console.log("server url está: " + serverUrl);
    const apiUrl = `${serverUrl}/login`; // Combinando o serverUrl com a rota específica
     
    
    const response = await axios.put(apiUrl, {
      EMAIL: username,
      SENHA: password,
      NOVA_SENHA: novasenha
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setformNovaSenhaVisible(false);

} catch (error) {
  console.error(error);
  setError('Senha não alterada');  
//   setSuccess(false); // Defina o estado de sucesso para false
}
};

  const handleLogin = async () => {
    try {
      // Fazer uma chamada à API para autenticar o usuário
      const apiUrl = `${serverUrl}/login`; // Combinando o serverUrl com a rota específica
       
      
      const response = await axios.post(apiUrl, {
        EMAIL: username,
        SENHA: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      // Lógica para lidar com a resposta da autenticação
       
      // Se a autenticação for bem-sucedida, armazene o token em um cookie
      if (response.data.token) {
        // Armazene o token em um cookie
        Cookies.set('token', response.data.token);
        setError(''); // Limpe a mensagem de erro
    
        // Defina o estado do nome do usuário no frontend
        
        setIsAuthenticated(true);
        
        const token = Cookies.get('token');
        if (token) 
        {
          setIsAuthenticated(true);  
          const decodedToken = jwt_decode(token);
          setNomeAdvogado(decodedToken.NOME);
          setIDAdvogado(decodedToken.ID);
          setOABAdvogado(decodedToken.OAB);
          setEstadoOABAdvogado(decodedToken.ESTADO_OAB);
          setEmailAdvogado(decodedToken.EMAIL);
          setAccessLevelAdvogado(decodedToken.ACCESS_LEVEL);
        }
        // Redirecione para a página inicial
        // history.push('/pagina-inicial');
      } else {
        // Caso contrário, mostre uma mensagem de erro
        setError('Não foi gerado TOKEN para sua Autenticação. Verifique usuário e senha ou bloqueio de Cookies.');
  //      setSuccess(false); // Defina o estado de sucesso para false
      }
    } catch (error) {
      console.error(error);
      setError('E-mail ou senha incorretos');  
   //   setSuccess(false); // Defina o estado de sucesso para false
      setIsAuthenticated(false);

    }
  };

  return (
    <AppContainer>
      <Header/>   
    <LoginContainer>

    {isAuthenticated ? (
                <>
                    <SuccessMessage>Login Bem Sucedido, {NOMEAdvogado}. Pode utilizar as funcionalidades do sistema.</SuccessMessage>
                    <LogoutButton onClick={() => handleTrocaSenha()} disabled={EmailAdvogado === 'teste'}>
            Alterar sua Senha
        </LogoutButton>
                    <LogoutButton onClick={() => handleLogout()}>Fazer Logout</LogoutButton>
                </>
            ) : (
          <>

      <Input
        type="text"
        placeholder="E-mail"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Senha"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button onClick={handleLogin}>Entrar</Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
        )}
      </LoginContainer>


      {formNovaSenhaVisible && (

<LoginContainer>
 

<Input ref={nomeInputRef}
        maxLength={50} // Set the maximum length to 50 characters
        value={novasenha}
        onChange={(e) => setnovasenha(e.target.value)}
        placeholder="Digite a nova senha"
    />
    <Button onClick={handleChangePassword}>Alterar a Senha</Button><br></br>
    <Button onClick={handleLogout}>Cancelar</Button>
    </LoginContainer>

      )}


 



    </AppContainer>
  );

  
};

export default LoginPage;
