import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import { Input, InputPesquisa, TextoGrande } from '../componentes/Input';
import { Titulo } from '../componentes/Titulo';
import AppContainer from '../componentes/AppContainer';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { useAuth } from '../AuthContext';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    FormContainer,
    TableContainer,
    Table,
    TableHeader,
    TableHeaderCell,
    TableHeaderButton,
    TableBody,
    TableRow,
    TableCell,
    ActionButton,
    CreateButton,
    ButtonContainer,
    InputContainer,
    InputLabel,
    SelectContainer,
    Select,
    Linkdogrid,
    TableTopRow,
  } from '../componentes/GridList/gridList'; // Importando os estilos do arquivo styles.js

  // Função para recuperar o valor de um cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const ClientePage = ({ isAuthenticated }) => {
    const [clientes, setClientes] = useState([]);
    const [editingCliente, setEditingCliente] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [nome, setNome] = useState('');
    const [dativo, setDativo] = useState(false);
    const [id_AdvogadoResponsavel, setid_AdvogadoResponsavel] = useState(false);
    const [advogados, setAdvogados] = useState([]);
    const { IDAdvogado } = useAuth();
    const nomeInputRef = useRef(null);
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [CPFCNPJ, setCPFCNPJ] = useState('');
    const [filterText, setFilterText] = useState("");
    const [GridVisible, setGridVisible] = useState(true);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filtroParam = queryParams.get('filtro');

    const apiUrl = `${serverUrl}/clientes`; // Combinando o serverUrl com a rota específica
    const apiUrlAdvogados = `${serverUrl}/advogados`; // Combinando o serverUrl com a rota específica

    const fetchClientes = async () => {
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setClientes(response.data);
        } catch (error) {
            console.error(error);
        }
    }; 

    const handleEditClick = (cliente) => {
        setEditingCliente(cliente);
        setGridVisible(false);
        setFormVisible(true); // Mostra o formulário ao editar
        setNome(cliente.NOME);
        setDativo(cliente.DATIVO);
        setTelefone(cliente.TELEFONE);
        setEmail(cliente.EMAIL);
        setid_AdvogadoResponsavel(cliente.ID_ADVOGADORESPONSAVEL);
        setCPFCNPJ(cliente.CPFCNPJ);
    };

    const handleCancelEdit = () => {
        clearForm();
        setFormVisible(false); // Esconde o formulário após salvar/atualizar
        setGridVisible(true);

    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${apiUrl}/${id}`, {
                headers: {
                    Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
            });
            fetchClientes();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const clienteData = {
                NOME: nome,
                ID_ADVOGADORESPONSAVEL: id_AdvogadoResponsavel,             
                DATIVO: dativo,
                TELEFONE: telefone,
                EMAIL: email,
                CPFCNPJ: CPFCNPJ
            };
    
            if (editingCliente) {
                // Atualizar cliente
                await axios.put(`${apiUrl}/${editingCliente.ID}`, clienteData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            } else {
                // Criar cliente                
                await axios.post(apiUrl, clienteData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            }
    
            fetchClientes();
            clearForm();
            setFormVisible(false); // Esconde o formulário após salvar/atualizar
            setGridVisible(true);

        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateButtonClick = () => {
        clearForm();
        setFormVisible(true);
        setDativo(false);
        setGridVisible(false);
  };
    

    const handleDativoChange = (e) => {
        setDativo(e.target.value === 'true'); // Converte a string 'true' ou 'false' para booleano
      };
    

    const clearForm = () => {
        setEditingCliente(null);
        setNome('');
        setDativo('');
        setTelefone('');
        setEmail('');
        setCPFCNPJ('');
    };

    const fetchAdvogados = async () => {
        try {
            const response = await axios.get(apiUrlAdvogados, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setAdvogados(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAdvogadoResponsavelChange = e => {
        const selectedAdvogadoId = e.target.value;
        setid_AdvogadoResponsavel(selectedAdvogadoId);
      };

      // Função para remover os parênteses, espaço e traço do número de telefone
const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/[\s()-]/g, '');
  };
    
      const Trata_Numero_Telefone = (e) => {
        const rawValue = e.target.value;
        const maskedValue = rawValue
          .replace(/\D/g, '') // Remove todos os não dígitos
          .substring(0, 11) // Limite de 14 caracteres
          .replace(/(\d{0,2})?(\d{0,5})?(\d{0,4})?/, (match, p1, p2, p3) => {
            let mask = '';
            if (p1) mask += `(${p1}`;
            if (p2) mask += `) ${p2}`;
            if (p3) mask += `-${p3}`;
            return mask;
          });
    
        setTelefone(maskedValue);
      };
      
      const filteredClientes = clientes.filter((cliente) => {
        const nome = cliente.NOME ? cliente.NOME.toLowerCase() : '';
        const email = cliente.EMAIL ? cliente.EMAIL.toLowerCase() : '';
        const telefone = cliente.TELEFONE ? cliente.TELEFONE.toLowerCase() : '';
        const cpfcnpj = cliente.CPFCNPJ ? cliente.CPFCNPJ.toLowerCase() : '';
      
        return (
          nome.includes(filterText.toLowerCase()) ||
          email.includes(filterText.toLowerCase()) ||
          telefone.includes(filterText.toLowerCase()) ||
          cpfcnpj.includes(filterText.toLowerCase())
        );
      });
      
      

      useEffect(() => {
        fetchClientes();
        fetchAdvogados();
        setFilterText(filtroParam || '');
        
        // Verificar se é uma situação de edição e pré-selecionar valores do contexto
        if (!editingCliente) {
     
          setid_AdvogadoResponsavel(IDAdvogado);
        }
        if (formVisible && nomeInputRef.current) {
            nomeInputRef.current.focus();
          }
      }, [editingCliente, IDAdvogado,formVisible]);

    return (
        <AppContainer>
    <Header isAuthenticated={isAuthenticated} />
    
    
    <TableContainer>
    <Titulo tamanhoFonte="24px" alinhamento="center" cor="white">Gerenciamento de Clientes</Titulo>

    {GridVisible && (

        <Table>
        <TableHeader> 
            
        <TableTopRow> <TableHeaderCell transparent colSpan={2}>
        <TableHeaderButton> <CreateButton onClick={() => handleCreateButtonClick()}>
    Criar Novo
</CreateButton></TableHeaderButton>

</TableHeaderCell>

<TableHeaderCell transparent colSpan={5}>

<TableHeaderButton> 
                <InputPesquisa
                type="text"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Digite qualquer coisa para filtrar"
                />
                 </TableHeaderButton>
                 </TableHeaderCell>
               </TableTopRow>
            </TableHeader>
            
            <TableHeader>
                <TableRow>
                    
                    <TableHeaderCell>Nome do Cliente</TableHeaderCell>  
                    <TableHeaderCell>Whatsapp</TableHeaderCell>  
                    
                    <TableHeaderCell>E-mail</TableHeaderCell>                                      
                    
                    <TableHeaderCell>CPF/CNPJ</TableHeaderCell>
                    <TableHeaderCell>Origem</TableHeaderCell>
                    <TableHeaderCell isActionsColumn>Ações</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
  {filteredClientes.map((cliente) => {
    // Formate o número de telefone
    const formattedPhoneNumber = formatPhoneNumber(cliente.TELEFONE);

    return (
      <TableRow key={cliente.ID}>
        <TableCell>
          <Linkdogrid to={`/processo?filtro=${cliente.NOME}`}>{cliente.NOME}</Linkdogrid>
        </TableCell>
        <TableCell>
          <Linkdogrid target="_blank" to={`https://wa.me/${formattedPhoneNumber}`}>
            {cliente.TELEFONE}
          </Linkdogrid>
        </TableCell>
        <TableCell>{cliente.EMAIL}</TableCell>
        <TableCell>{cliente.CPFCNPJ}</TableCell>
        <TableCell>{cliente.DATIVO === 0 ? 'Particular' : 'Dativo'}</TableCell>
        <TableCell isActionsColumn>
          <ActionButton onClick={() => handleEditClick(cliente)}>Editar</ActionButton>
          <ActionButton onClick={() => handleDeleteClick(cliente.ID)}>Excluir</ActionButton>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>
        </Table>

         )}
    </TableContainer>

   

    <br></br>
    {formVisible && (

<FormContainer showCrumble>
<InputContainer>
<InputLabel>Nome do Cliente:</InputLabel>
<Input ref={nomeInputRef}
        maxLength={50} // Set the maximum length to 30 characters
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        placeholder="Nome do cliente"
    />
</InputContainer>

<InputContainer>
<InputLabel>Whatsapp do Cliente:</InputLabel>
<Input        
        value={telefone}
        onChange={Trata_Numero_Telefone}
        placeholder="(NN)NNNNN-NNNN"
    />
</InputContainer>

<InputContainer>
<InputLabel>E-mail do Cliente:</InputLabel>
<Input 
        maxLength={42}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail do cliente"
    />
</InputContainer>

<SelectContainer>
        <InputLabel>Adv. Responsavel:</InputLabel>
        <Select value={id_AdvogadoResponsavel} onChange={handleAdvogadoResponsavelChange} disabled>          
          {advogados.map(advogado => (
            <option key={advogado.ID} value={advogado.ID}>
              {advogado.NOME}
            </option>
          ))}
        </Select>
      </SelectContainer>

<SelectContainer>
  <InputLabel>Dativo:</InputLabel>
  <Select value={dativo ? 'true' : 'false'} onChange={handleDativoChange}>
    <option value="false">Particular</option>
    <option value="true">Dativo</option>
  </Select>
</SelectContainer>

<InputContainer>
<InputLabel>CPF/CNPJ do Cliente:</InputLabel>
<Input  
        maxLength={50} // Set the maximum length to 30 characters
        value={CPFCNPJ}
        onChange={(e) => setCPFCNPJ(e.target.value)}
        placeholder="CPF ou CNPJ"
    />
</InputContainer>


<ButtonContainer>
    <ActionButton onClick={handleSubmit}>
        {editingCliente ? 'Atualizar Cliente' : 'Salvar como Novo Cliente'}
    </ActionButton>
  
    
    <ActionButton onClick={handleCancelEdit} style={{ display: editingCliente ? 'block' : 'block' }}>
    {editingCliente ? 'Cancelar Edição' : 'Cancelar Criação'}
</ActionButton>
</ButtonContainer>

    </FormContainer>
)}
</AppContainer>
    );
};

export default ClientePage;
