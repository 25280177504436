import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import Input from '../componentes/Input';
import { Titulo } from '../componentes/Titulo';
import AppContainer from '../componentes/AppContainer';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { useRef } from 'react';
import {
    FormContainer,
    TableContainer,
    Table,
    TableHeader,
    TableHeaderCell,
    TableHeaderButton,
    TableBody,
    TableRow,
    TableCell,
    ActionButton,
    CreateButton,
    ButtonContainer,
    InputContainer,
    InputLabel,
  } from '../componentes/GridList/gridList'; // Importando os estilos do arquivo styles.js

  // Função para recuperar o valor de um cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const AdvogadoPage = ({ isAuthenticated }) => {
    const [advogados, setAdvogados] = useState([]);
    const [editingAdvogado, setEditingAdvogado] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [nome, setNome] = useState('');
    const [oab, setOAB] = useState('');
    const [estadoOAB, setEstadoOAB] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const nomeInputRef = useRef(null);

    const apiUrl = `${serverUrl}/advogados`; // Combinando o serverUrl com a rota específica

    const fetchAdvogados = async () => {
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setAdvogados(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditClick = (advogado) => {
        setEditingAdvogado(advogado);
        setFormVisible(true); // Mostra o formulário ao editar
        setNome(advogado.NOME);
        setOAB(advogado.OAB);
        setEstadoOAB(advogado.ESTADO_OAB);
        setEmail(advogado.EMAIL);
        setSenha(advogado.SENHA);
    };

    const handleCancelEdit = () => {
        clearForm();
        setFormVisible(false); // Esconde o formulário após salvar/atualizar

    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${apiUrl}/${id}`, {
                headers: {
                    Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
            });
            fetchAdvogados();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const advogadoData = {
                NOME: nome,
                OAB: oab,
                ESTADO_OAB: estadoOAB,
                EMAIL: email,
                SENHA: senha // Adicione a senha ao objeto advogadoData
            };
    
            if (editingAdvogado) {
                // Atualizar advogado
                await axios.put(`${apiUrl}/${editingAdvogado.ID}`, advogadoData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            } else {
                // Criar advogado                
                await axios.post(apiUrl, advogadoData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            }
    
            fetchAdvogados();
            clearForm();
            setFormVisible(false); // Esconde o formulário após salvar/atualizar

        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateButtonClick = () => {
        clearForm();
        setFormVisible(true);
    };

    const clearForm = () => {
        setEditingAdvogado(null);
        setNome('');
        setOAB('');
        setEstadoOAB('');
        setEmail('');
        setSenha('');
    };

    useEffect(() => {
        fetchAdvogados();
        if (formVisible && nomeInputRef.current) {
            nomeInputRef.current.focus();
          }
    }, [formVisible]);

    return (
        <AppContainer>
    <Header isAuthenticated={isAuthenticated} />
    
    
    <TableContainer>
    <Titulo tamanhoFonte="24px" alinhamento="center" cor="white">Gerenciamento de Advogados</Titulo>
        
        <Table>
        <TableHeader> 
        <TableHeaderButton> <CreateButton onClick={() => handleCreateButtonClick()}>
    Criar Novo
</CreateButton></TableHeaderButton>
               
            </TableHeader>
            
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Nome</TableHeaderCell>
                    <TableHeaderCell>OAB</TableHeaderCell>
                    <TableHeaderCell>Estado OAB</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell isActionsColumn>Ações</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {advogados.map((advogado) => (
                    <TableRow key={advogado.ID}>
                        <TableCell>{advogado.NOME}</TableCell>
                        <TableCell>{advogado.OAB}</TableCell>
                        <TableCell>{advogado.ESTADO_OAB}</TableCell>
                        <TableCell>{advogado.EMAIL}</TableCell>
                        <TableCell isActionsColumn>
                            <ActionButton onClick={() => handleEditClick(advogado)}>Editar</ActionButton>
                            <ActionButton onClick={() => handleDeleteClick(advogado.ID)}>Excluir</ActionButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

    <br></br>
    {formVisible && (

<FormContainer >
<InputContainer>
<InputLabel>Nome do Advogado:</InputLabel>
<Input ref={nomeInputRef}
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        placeholder="Nome do advogado"
    />
</InputContainer>

<InputContainer>
<InputLabel>Número OAB:</InputLabel>
    <Input 
        value={oab}
        onChange={(e) => setOAB(e.target.value)}
        placeholder="OAB"
    />
 </InputContainer>
 <InputContainer>
<InputLabel>Estado da OAB:</InputLabel>
    <Input
        value={estadoOAB}
        onChange={(e) => setEstadoOAB(e.target.value)}
        placeholder="Estado OAB"
    />
</InputContainer>
<InputContainer>
<InputLabel>E-mail:</InputLabel>
    <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
    />
</InputContainer>
<InputContainer>
<InputLabel>Senha:</InputLabel>
    <Input
        value={senha}
        onChange={(e) => setSenha(e.target.value)}
        type="text"
        placeholder="Senha"
    />
</InputContainer>
<ButtonContainer>
    <ActionButton onClick={handleSubmit}>
        {editingAdvogado ? 'Atualizar Advogado' : 'Salvar como Novo Advogado'}
    </ActionButton>
  
    
    <ActionButton onClick={handleCancelEdit} style={{ display: editingAdvogado ? 'block' : 'block' }}>
    {editingAdvogado ? 'Cancelar Edição' : 'Cancelar Criação'}
</ActionButton>
</ButtonContainer>

    </FormContainer>
)}
</AppContainer>
    );
};

export default AdvogadoPage;
