import styled from 'styled-components';

// Define a styled component for the suggestion item
export const SuggestionItem = styled.div`
  background-color: #b3e0f2; /* Light blue background color */
  padding: 8px 12px; /* Adjust padding as needed */
  border: 1px solid #007bff; /* Blue border */
  border-radius: 4px;
  color: #333; /* Text color */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.2s ease-in-out; /* Smooth background color transition */

  /* Additional styles as needed */

  /* Hover state styles */
  &:hover {
    background-color: #007bff; /* Darker blue background color on hover */
    color: #fff; /* White text color on hover */
  }
`;

export default SuggestionItem;