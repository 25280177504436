import Header from '../componentes/Header';
import AppContainer from '../componentes/AppContainer';
import styled from 'styled-components'
import { useAuth } from '../AuthContext';


const Conteudo = styled.p`
    color: #FFF;
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 20px 0;
`;

function HomePage() {
  const { isAuthenticated, NOMEAdvogado } = useAuth();
  return (
    <AppContainer>
      <Header />
      <Conteudo>
        {isAuthenticated ? `Bem-vindo ao Sistema, ${NOMEAdvogado} !` : 'Faça login para usufruir do Sistema ou use login: teste / senha: teste para testar'}        
      </Conteudo>
    </AppContainer>
  );
}

export default HomePage;
