import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell'; // Importação do TableCell do MUI
import Snackbar from '@mui/material/Snackbar'; // Importação do Snackbar do MUI
import Alert from '@mui/material/Alert'; // Importação do Alert do MUI

export const CopyableTableCell = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Esconde o aviso após 2 segundos
    });
  };

  return (
    <TableCell onClick={handleCopy} style={{ cursor: 'pointer', position: 'relative' }}>
      {content}
      {copied && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          opacity: 0.8,
        }}>
          Copiado
        </div>
      )}
    </TableCell>
  );
};

export default CopyableTableCell;