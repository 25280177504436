import styled from 'styled-components'
import Header from '../componentes/Header';
import Input from '../componentes/Input'
import AppContainer from '../componentes/AppContainer';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { useLocation } from 'react-router-dom';

const Conteudo = styled.p`
    color: #FFF;
    font-size: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 20px 0;
`;

const PesquisaContainer = styled.section`
        background-image: linear-gradient(90deg, #002F52 35%, #326589 165%);
        color: #FFF;
        text-align: center;
        padding: 85px 0;
        height: 270px;
        width: 100%;
`

const Titulo = styled.h2`
        color: #FFF;
        font-size: 36px;
        text-align: center;
        width: 100%;
`

const Subtitulo = styled.h3`
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
`

const ListaResultados = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
  align-items: center;
`;

const ItemResultado = styled.li`
  font-size: 18px;
  color: white;
  margin: 10px 0;
`;



// Função para recuperar o valor de um cookie
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

function DativosPage() {
  const { isAuthenticated, NOMEAdvogado } = useAuth();
  const [nome, setNome] = useState('');
  const [resultados, setResultados] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filtroParam = queryParams.get('filtro');
  const [ultimalista, setUltimalista] = useState('');

  

  const apiUrl = `${serverUrl}/pesquisadativos`; // Combinando o serverUrl com a rota específica
  const apiUrlUltimaLista = `${serverUrl}/pesquisadativos/ultimalista`; // Combinando o serverUrl com a rota específica


  const buscarResultados = async () => {
 
    try {
      if (nome.length < 5) {
        setResultados([]);
        return; // Sai da função se a pesquisa for menor que 4 caracteres
      }
        const response = await axios.get(`${apiUrl}?nome=${nome}`, {
            headers: {
              Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
            }
          });
      //console.log(getCookie('token'));
      setResultados(response.data);
    } catch (error) {
      console.error(error);
    }   
  };

  const buscarUltimaLista = async () => {
 
    try {
         const response = await axios.get(`${apiUrlUltimaLista}`, {
            headers: {
              Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
            }
          });
      //console.log(getCookie('token'));
      setUltimalista(response.data);
    } catch (error) {
      console.error(error);
    }   
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      buscarResultados();
    }
  };

 


  useEffect(() => {
    buscarUltimaLista();  
    // Verifica se filtroParam não é vazio e chama setNome
    if (filtroParam) {
      setNome(filtroParam);
      buscarResultados();
    }

  }, [filtroParam, setNome]);

    return (
    <AppContainer>
    <Header/>   
     <Titulo>DATIVOS</Titulo>
    <Conteudo>
      <PesquisaContainer>
            <Titulo>Pesquisa Recebimentos (a partir de 2013, até {ultimalista}) </Titulo>
            <Subtitulo>Digite seu nome ou o número do Processo (mínimo 5 caracteres)</Subtitulo>
            <Subtitulo>formato ex: 0000828-90.2021.8.08.0024 ou antigo</Subtitulo>
           
            
      <Input
        type="text"
        value={nome}
        onChange={(e) => setNome(e.target.value)}
        placeholder={`${NOMEAdvogado}`}
        onKeyDown={handleKeyDown}
      />
      <button onClick={buscarResultados}>Buscar</button>
      </PesquisaContainer>


      <ListaResultados>
        {resultados.map((resultado, index) => (
          <ItemResultado key={index}>
          {resultado.data_lista_dativos} - {resultado.linha_pdf}
        </ItemResultado>
        ))}
      </ListaResultados>
   

     

       
    </Conteudo>
    </AppContainer>
  );
    }

    export default DativosPage