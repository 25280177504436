import React, {useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import { Input, InputPesquisa, TextoGrande, AutosuggestWrapper } from '../componentes/Input';
import { Checkbox } from '../componentes/CheckBox';
import { Titulo } from '../componentes/Titulo';
import { ListComponent, ListContainer } from '../componentes/ListComponent'; // Certifique-se de ajustar o caminho conforme necessário
import AppContainer from '../componentes/AppContainer';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { useAuth } from '../AuthContext';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import SuggestionItem from '../componentes/AutoSuggest/styled-suggests';
import ConfirmationButton from '../componentes/ConfirmationButton';
import styled from 'styled-components'

import CopyableTableCell from '../componentes/CopyableTablecell';

import {
    FormContainer,
    TableContainer,
    Table,
    TableHeader,
    TableHeaderCell,
    TableHeaderButton,
    TableBody,
    TableRow,
    TableCell,
    ActionButton,
    CreateButton,
    ButtonContainer,
    InputContainer,
    InputLabel,
    SelectContainer,
    Select,
    TableTopRow,
    DescricaoLabel,
    HorizontalFormContainer,
    Linkdogrid,
  } from '../componentes/GridList/gridList'; // Importando os estilos do arquivo styles.js

 

const ListaResultados = styled.ul`
  
  padding: 0;
  margin: 20px 0;
  align-items: center;
`;

const ItemResultado = styled.li`
  font-size: 18px;
  color: white;
  margin: 10px 0;
`;



  // Função para recuperar o valor de um cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const ProcessoPage = ({ isAuthenticated }) => {
    const [processos, setProcessos] = useState([]);
    const [editingProcesso, setEditingProcesso] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [HistoricoVisible, setHistoricoVisible] = useState(false);   
    const [TarefasVisible, setTarefasVisible] = useState(false); 
    const [Numero_Processo , setNumero_Processo] = useState('');
    const [Descricao , setDescricao ] = useState(false);
    const [ID_AdvogadoProcesso, setID_AdvogadoProcesso] = useState(false);
    const [advogados, setAdvogados] = useState([]);
    const [fases, setFases] = useState([]);
    const [ID_faseprocessual, setID_FaseProcessual] = useState(14);
    const [clientes, setClientes] = useState([]);
    const {IDAdvogado } = useAuth();
    const [ID_ClienteProcesso , setID_ClienteProcesso ] = useState(false);
    const nomeInputRef = useRef(null);
    const [Historico, setHistorico] = useState('');
    const [DescricaoNovaTarefa, setDescricaoNovaTarefa] = useState('');
    const [filterText, setFilterText] = useState("");
    const [GridVisible, setGridVisible] = useState(true);
    const [NomeClienteProcesso, SetNomeClienteProcesso] = useState(true);
    const [searchValue_Cliente, setSearchValue_Cliente] = useState(''); // Input value
    const [suggestions_Cliente, setSuggestions_Cliente] = useState([]); // Suggestions
    const [TarefasProcesso, setTarefasProcesso] = useState([]); 
    const [dativo, setDativo] = useState(false);
    const [dativofiltro, setDativofiltro] = useState('todos');
    const [fasefiltro, setFasefiltro] = useState('todos');    
    const [tarefafazerfiltro, setTarefafazerfiltro] = useState('todos');  
    const [tarefafazer, setTarefafazer] = useState(false);

    const [isClienteSelected, setIsClienteSelected] = useState(false);

    const [nome, setNome] = useState('');
    const [resultados, setResultados] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filtroParam = queryParams.get('filtro');


    const [AssuntoCNJ,setAssuntoCNJ] = useState('');
    const [AndamentosCNJ, setAndamentosCNJ] = useState([]);



    const [responseDataCNJ, setResponseDataCNJ] = useState(null);
    const [errorCNJ, setErrorCNJ] = useState(null);
    

    const apiUrl = `${serverUrl}/processos`; // Combinando o serverUrl com a rota específica
    const apiUrlAdvogados = `${serverUrl}/advogados`; // Combinando o serverUrl com a rota específica
    const apiUrlClientes = `${serverUrl}/clientes`; // Combinando o serverUrl com a rota específica
    const apiUrlTarefasProcesso = `${serverUrl}/tarefas-processo`; // Combinando o serverUrl com a rota específica
    const apiUrlPesquisaDativos = `${serverUrl}/pesquisadativos`; // Combinando o serverUrl com a rota específica
    const apiUrlFasesProcessos = `${serverUrl}/processos/fasesprocessos`; // Combinando o serverUrl com a rota específica
    const apiUrlAlternaTarefaProcesso = `${serverUrl}/processos/setatarefa`; // Combinando o serverUrl com a rota específica

    const apiUrlConsultaCNJ = `${serverUrl}/apicnj`; // Combinando o serverUrl com a rota específica
   
    
    const FetchProcessoCNJ = async () => {
      try {      
        setErrorCNJ(""); // Limpa o erro anterior
        setResponseDataCNJ(null); // Limpa os dados anteriores
          //Aqui vai pegar só para exibir nos detalhes pois pega da memória o num processo selecionado
          // falta limpar o numero do processo quando volta pra tela de listagem
          // e limpar o setResponseDataCNJ="";
        const Numero_a_Passar = Numero_Processo.replace(/[.\-]/g, '');

        const response = await axios.post(`${apiUrlConsultaCNJ}`, {
          query: {
            match: {
              NUM_PROCESSO: Numero_a_Passar,
            },
          },
          headers: {
            Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
          }
        });
        setResponseDataCNJ(response.data);
        console.dir(response.data);


        setAndamentosCNJ(["This is a sample string with less than 100 characters.","Another string that will be displayed in a separate line with curved borders.","Here is a longer string that might just hit the maximum character limit, but it will still look nice with the border radius."]);
      } catch (error) {
        setErrorCNJ(error.message);
      }
    };

    const buscarResultados = async (processo) => {
       try {
        
          
          setResultados([]);
          
     
          const response = await axios.get(`${apiUrlPesquisaDativos}?nome=${processo.Numero_Processo}`, {
              headers: {
                Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
              }
            });
        //console.log(getCookie('token'));
        setResultados(response.data);
        
      } catch (error) {
        console.error(error);
      }   
    };
  
    

    const fetchProcessos = useCallback(async () => {
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setProcessos(response.data);
        } catch (error) {
            console.error(error);
        }
      },[apiUrl]);

    const handleEditClick = (processo) => {
        setEditingProcesso(processo);
        setFormVisible(true); // Mostra o formulário ao editar
        setGridVisible(false);
        setHistoricoVisible(false);
        setTarefasVisible(false);
        setNumero_Processo(processo.Numero_Processo);
        setDescricao(processo.Descricao);
        setID_ClienteProcesso(processo.ID_ClienteProcesso);
        setID_AdvogadoProcesso(processo.ID_AdvogadoProcesso);
        setHistorico(processo.Historico);
        setSearchValue_Cliente(processo.nome_completo_cliente);
        setIsClienteSelected(true);
        setDativo(processo.Dativo);
        setID_FaseProcessual(processo.id_fase_processo);
        setTarefafazer(processo.tarefa);
        
        
    };

    

     const HandleHistoricoClick = (processo) => {
        setEditingProcesso(processo);
        setFormVisible(false);
        setGridVisible(false);
        setHistoricoVisible(true);
        setTarefasVisible(false);
        setNumero_Processo(processo.Numero_Processo);
        setDescricao(processo.Descricao);
        setDativo(processo.Dativo);
        setID_ClienteProcesso(processo.ID_ClienteProcesso);
        setID_AdvogadoProcesso(processo.ID_AdvogadoProcesso);
        setID_FaseProcessual(processo.id_fase_processo);
        setHistorico(processo.Historico);
        SetNomeClienteProcesso(processo.nome_completo_cliente);
        setTarefafazer(processo.tarefa);
        fetchTarefas(processo.ID);
        fetchFases();
        buscarResultados(processo);



    }

      const handleCancelEdit = () => {
        clearForm();
        setFormVisible(false); // Esconde o formulário após salvar/atualizar
        setHistoricoVisible(false);
        setTarefasVisible(false);
        setGridVisible(true);
        setIsClienteSelected(false);

    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${apiUrl}/${id}`, {
                headers: {
                    Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
            });
            fetchProcessos();
        } catch (error) {
            console.error(error);
        }
    };

    const alternatarefaClick = async (id) => {
      try {
          await axios.put(`${apiUrlAlternaTarefaProcesso}/${id}`, null, {
              headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
              }
          });
          fetchProcessos();
      } catch (error) {
          console.error(error);
      }
  };


    const handleSubmit = async (e) => {
        e.preventDefault();

       
        let id_cliente_a_Inserir = null;

   



        // preciso verificar se o cliente tá selecionado ou se é novo
        // se for novo, cria antes de inserir o processo
        // se for velho, usa ele.

        if (isClienteSelected || HistoricoVisible) {
          id_cliente_a_Inserir = ID_ClienteProcesso;
      } else {
        
        
          try {
            console.log("vai criar cliente rapido com dativo = " + dativo);
 
            
            const clienteDataTemp = {
              NOME: searchValue_Cliente,
              ID_ADVOGADORESPONSAVEL: ID_AdvogadoProcesso,
              DATIVO: dativo,
              TELEFONE: "(27) 00000-0000",
              EMAIL: "cliente-sem-email@sem-email.com",
              CPFCNPJ: "000.000.000-00"
          };
              // Cria um novo cliente chamando API e muda o ID do cliente para o recém-criado.
              const response = await axios.post(apiUrlClientes, clienteDataTemp, {
                  headers: {
                      Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                  }
              });
      
              // Extraia o ID do cliente recém-criado da resposta e atualize seu estado ou faça algo com ele, se necessário.
              const novoClienteId = response.data.id; // Certifique-se de ajustar isso com base na estrutura real da resposta.
              id_cliente_a_Inserir = novoClienteId;
              
              // Faça o que for necessário com o ID do novo cliente.
               
          } catch (error) {
              console.error('Erro ao criar novo cliente rapido:', error);
      
              // Adicione o tratamento de erro conforme necessário.
          }
      }
      console.log ("terminou o cliente rapido (ou nem precisou criar) e vai criar o processo agora com Dativo=" + dativo);
        try {
            console.log("vai tentar inserir a seguinte fase processual codigo:" + ID_faseprocessual);
            const processoData = {
                Numero_Processo: Numero_Processo,
                Descricao: Descricao,             
                ID_AdvogadoProcesso: ID_AdvogadoProcesso,
                ID_ClienteProcesso: id_cliente_a_Inserir,
                Historico: Historico,
                Dativo: dativo,
                id_fase_processo: ID_faseprocessual,
                tarefa: tarefafazer
            };
    
            if (editingProcesso) {
                // Atualizar processo
                await axios.put(`${apiUrl}/${editingProcesso.ID}`, processoData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            } else {
                // Criar processo                
                await axios.post(apiUrl, processoData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            }
    
            fetchProcessos();
            clearForm();
            setFormVisible(false); // Esconde o formulário após salvar/atualizar
            setGridVisible(true);
            setHistoricoVisible(false);
            setTarefasVisible(false);

        } catch (error) {
            console.error(error);
        }
    };
    


    const handleCreateButtonClick = () => {
        clearForm();
        setFormVisible(true);
        setGridVisible(false);
        setTarefasVisible(false);
        setDativo(false);
        // Preinicialize o valor do input com filtroParam
        setSearchValue_Cliente(filtroParam);
        
  };
    

  
    const clearForm = () => {
        setEditingProcesso(null);
        setNumero_Processo('');
        setDescricao('');
        setID_ClienteProcesso('');
        setID_AdvogadoProcesso('');
        setHistorico('');
        setSearchValue_Cliente('');
        setIsClienteSelected(false);
        setDativo('');


    };

    const fetchAdvogados = useCallback(async () => {
        try {
            const response = await axios.get(apiUrlAdvogados, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setAdvogados(response.data);
        } catch (error) {
            console.error(error);
        }
      },[apiUrlAdvogados]);


      const fetchFases = useCallback(async () => {
        console.log("entrou em fetchfases");
        try {
            const response = await axios.get(apiUrlFasesProcessos, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setFases(response.data);
            console.log("finalizou e carregou as fases");
        } catch (error) {
          console.log("falhou o fetchfases com a url: " + apiUrlFasesProcessos);
            console.error(error);
        }
      },[apiUrlFasesProcessos]);


      const fetchTarefas = useCallback(async (id) => {
        try {
          const response = await axios.get(`${apiUrlTarefasProcesso}/${id}`, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setTarefasProcesso(response.data);
            
        } catch (error) {
           
            console.error(error);
        }
      },[apiUrlTarefasProcesso]);

    const fetchClientes = useCallback(async () => {
        try {
            const response = await axios.get(apiUrlClientes, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setClientes(response.data);
        } catch (error) {
            console.error(error);
        }
      },[apiUrlClientes]);

    const handleAdvogadoResponsavelChange = e => {
        const selectedAdvogadoId = e.target.value;
        setID_AdvogadoProcesso(selectedAdvogadoId);
      };

      const handleFaseProcesssualChange = e => {
        const selectedFaseProcessual = e.target.value;
        setID_FaseProcessual(selectedFaseProcessual);
      };

      const handleClienteResponsavelChange = e => {
        const selectedClienteId = e.target.value;
        setID_ClienteProcesso(selectedClienteId);
      };

      const Trata_Numero_Processo = (e) => {
        const rawValue = e.target.value;
        const maskedValue = rawValue
          .replace(/\D/g, '') // Remove todos os não dígitos
          .substring(0, 20) // Limite de 25 caracteres
          .replace(/(\d{0,7})?(\d{0,2})?(\d{0,4})?(\d{0,1})?(\d{0,2})?(\d{0,4})?/, (match, p1, p2, p3, p4, p5, p6) => {
            let mask = '';
            if (p1) mask += p1;
            if (p2) mask += `-${p2}`;
            if (p3) mask += `.${p3}`;
            if (p4) mask += `.${p4}`;
            if (p5) mask += `.${p5}`;
            if (p6) mask += `.${p6}`;
            return mask;
          }); 
    
        setNumero_Processo(maskedValue);
      };

      const filteredProcessos = processos.filter((processo) => {
        // Primeiro verifica se o processo corresponde ao filtro de texto
        const matchesFilterText = processo.Numero_Processo.toLowerCase().includes(filterText.toLowerCase()) ||
            processo.Descricao.toLowerCase().includes(filterText.toLowerCase()) ||
            processo.primeiro_nome_advogado.toLowerCase().includes(filterText.toLowerCase()) ||
            processo.nome_completo_cliente.toLowerCase().includes(filterText.toLowerCase());
    
        // Verifica se o processo corresponde ao filtro de tarefa a fazer
        const matchesTarefaFazer = tarefafazerfiltro === "1" ? processo.tarefa === 1 : true;
    
        // Se dativofiltro for 'todos' e fasefiltro for 'todos', ignora a filtragem por Dativo e Fase
        if (dativofiltro === 'todos' && fasefiltro === 'todos') {
            return matchesFilterText && matchesTarefaFazer;
        }
    
        // Se dativofiltro for 'todos', verifica apenas a correspondência com fasefiltro
        if (dativofiltro === 'todos') {
            const matchesFases = parseInt(fasefiltro, 10) === processo.id_fase_processo;
            return matchesFilterText && matchesFases && matchesTarefaFazer;
        }
    
        // Se fasefiltro for 'todos', verifica apenas a correspondência com dativofiltro
        if (fasefiltro === 'todos') {
            const matchesDativo = parseInt(dativofiltro, 10) === processo.Dativo;
            return matchesFilterText && matchesDativo && matchesTarefaFazer;
        }
    
        // Verifica a correspondência com ambos dativofiltro e fasefiltro
        const matchesDativo = parseInt(dativofiltro, 10) === processo.Dativo;
        const matchesFases = parseInt(fasefiltro, 10) === processo.id_fase_processo;
        return matchesFilterText && matchesDativo && matchesFases && matchesTarefaFazer;
    });
    
    

    //***************Suggestion Clientes*********************************** */

    const HandleConcluirTarefa = async (tarefaID, tarefaConcluida, idProcesso) => {
      try {
        const novoEstadoConcluido = tarefaConcluida === 1 ? 0 : 1;
        await axios.patch(`${apiUrlTarefasProcesso}/${tarefaID}`, {
          TAREFA_CONCLUIDA: novoEstadoConcluido, // Inclua a variável TAREFA_CONCLUIDA no corpo da requisição
        }, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        });
         
        fetchTarefas(idProcesso);
      } catch (error) {
        
        console.error(error);
      }
    };
    
  
    const handleDativoChange = (e) => {
      setDativo(e.target.value === 'true'); // Converte a string 'true' ou 'false' para booleano
    };

    // Function to handle input value change
const handleSearchChange_Cliente = (event, { newValue }) => {
    setSearchValue_Cliente(newValue);
  };
  
  // Function to fetch suggestions based on input
  const fetchSuggestionsBasedOnInput_Cliente = (inputValue) => {
    // Filter your 'clientes' array based on the inputValue
    const filteredClientes = clientes.filter((cliente) =>
      cliente.NOME.toLowerCase().includes(inputValue.toLowerCase())
    );
  
    return filteredClientes;
  };
  
  // Function to get the suggestion value to display
  const getSuggestionValue_Cliente = (suggestion) => suggestion.NOME;
  
  // Function to render each suggestion item
  const renderSuggestion_Cliente = (suggestion) => (
    <SuggestionItem>{suggestion.NOME}</SuggestionItem>
  );
  
  const renderInputComponent_Cliente = inputProps => (
    <div>
      <Input {...inputProps} />
    </div>
  );
    

      useEffect(() => {
        fetchProcessos();
        fetchAdvogados();
        fetchFases();
        fetchClientes();
        FetchProcessoCNJ();
        setFilterText(filtroParam || '');
        
        // Verificar se é uma situação de edição e pré-selecionar valores do contexto
        if (!editingProcesso) {
           
            setID_AdvogadoProcesso(IDAdvogado);  //essa variavel vem de AUTHcontext é o advogado logado
            setID_ClienteProcesso(ID_ClienteProcesso); //criar um auth e colocar o ultimo cliente modificado ou criado

        }
        if (formVisible && nomeInputRef.current) {
            nomeInputRef.current.focus();
          }
      }, [editingProcesso, fetchAdvogados, fetchClientes, fetchProcessos, fetchFases, ID_AdvogadoProcesso, IDAdvogado, ID_ClienteProcesso, formVisible, filtroParam]);



     
    return (
        <AppContainer>
    <Header isAuthenticated={isAuthenticated} />
    
    
    <TableContainer>
    <Titulo tamanhoFonte="24px" alinhamento="center" cor="white">Gerenciamento de Processos</Titulo>
   
    {GridVisible && (

<Table >
<TableHeader > 
    
<TableTopRow > <TableHeaderCell transparent colSpan={1}>
<TableHeaderButton> <CreateButton onClick={() => handleCreateButtonClick()}>
Criar Novo
</CreateButton></TableHeaderButton>

</TableHeaderCell>

<TableHeaderCell  transparent colSpan={1}>

<TableHeaderButton > 
        <InputPesquisa 
        type="text"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Digite qualquer coisa para filtrar"
        />
         </TableHeaderButton>
         </TableHeaderCell>

         <TableHeaderCell  transparent colSpan={2}>

   <SelectContainer>
  <InputLabel>Origem:</InputLabel>
  <Select value={dativofiltro} onChange={(e) => setDativofiltro(e.target.value)}>
  <option value="todos">Todos</option>
    <option value="0">Particular</option>
    <option value="1">Dativo</option>
 
  </Select>
</SelectContainer>
</TableHeaderCell>

<TableHeaderCell  transparent colSpan={3}>

<Checkbox
  checked={tarefafazerfiltro === "1"}
  onChange={(e) => setTarefafazerfiltro(e.target.checked ? "1" : "0")}
  label="Somente os que exigem Atenção"
/>

</TableHeaderCell>

 

 

       </TableTopRow>
    </TableHeader>
            
            <TableHeader>
                <TableRow>
                     
                    <TableHeaderCell>Numero Processual</TableHeaderCell>  
                    <TableHeaderCell>Descricao</TableHeaderCell>  
                    <TableHeaderCell>Advogado</TableHeaderCell> 
                     
                    <TableHeaderCell>Atenção</TableHeaderCell>   
                    <TableHeaderCell>Origem</TableHeaderCell>                 
                    <TableHeaderCell>Cliente</TableHeaderCell>
   
                    <TableHeaderCell isActionsColumn>Ações</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {filteredProcessos.map((processo) => (
                    <TableRow key={processo.ID}>
                         
                         <CopyableTableCell content={processo.Numero_Processo} />
                        <TableCell>{processo.Descricao}</TableCell>
                        <TableCell>{processo.primeiro_nome_advogado}</TableCell>
                        

                        <TableCell>
  {processo.tarefa !== 0 ? (
    <button onClick={() => alternatarefaClick(processo.ID)}>
      Exige Atenção
    </button>
  ) : (
    <button onClick={() => alternatarefaClick(processo.ID)}>
      OK
    </button>
  )}
</TableCell>


                        <TableCell>
      {processo.Dativo !== 0 ? (
        <Linkdogrid to={`/dativos?filtro=${processo.Numero_Processo}`}>
          Dativo
        </Linkdogrid>
      ) : (
        'Particular'
      )}
    </TableCell>
                        <TableCell>{processo.nome_completo_cliente}</TableCell>                                               

                        <TableCell isActionsColumn>
                            <ActionButton onClick={() => handleEditClick(processo)}>Editar</ActionButton>
                            <ActionButton onClick={() => HandleHistoricoClick(processo)}>Movimentar</ActionButton>
                            <ConfirmationButton
  onConfirm={() => handleDeleteClick(processo.ID)}
  buttonText="Excluir"
  confirmationMessage="Tem certeza que deseja excluir este item?"
/>
                            
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
         )}
    </TableContainer>

    <br></br>


    {formVisible && (


<FormContainer showCrumble>
<InputContainer>
<InputLabel>Numero do Processo:</InputLabel>
<Input ref={nomeInputRef}
        value={Numero_Processo}
        onChange={Trata_Numero_Processo}
        placeholder="NNNNNNN-NN.NNNN.N.NN.NNNN"
    />
</InputContainer>

<InputContainer>
<InputLabel>Descricao do Processo:</InputLabel>
<Input 
        maxLength={45} // Set the maximum length to 30 characters
        value={Descricao}
        onChange={(e) => setDescricao(e.target.value)}
        placeholder="Descricao do Processo"
    />
</InputContainer>

<SelectContainer>
        <InputLabel>Adv. Responsavel:</InputLabel>
        <Select value={ID_AdvogadoProcesso} onChange={handleAdvogadoResponsavelChange} disabled>          
          {advogados.map(advogado => (
            <option key={advogado.ID} value={advogado.ID}>
              {advogado.NOME}
            </option>
          ))}
        </Select>
      </SelectContainer>

      <SelectContainer>
  <InputLabel>Dativo:</InputLabel>
  <Select value={dativo ? 'true' : 'false'} onChange={handleDativoChange}>
    <option value="false">Particular</option>
    <option value="true">Dativo</option>
  </Select>
</SelectContainer>

      <InputContainer>
<InputLabel>Cliente:</InputLabel>
<AutosuggestWrapper isSelected={isClienteSelected}>
      <Autosuggest
  inputProps={{
    placeholder: 'Para alterar o cliente digite o nome aqui',
    value: searchValue_Cliente || '',
    onChange: handleSearchChange_Cliente,
    disabled: isClienteSelected && !editingProcesso,  // Adicionando a propriedade disabled
  }}
  suggestions={suggestions_Cliente}
  onSuggestionsFetchRequested={({ value }) => {
    const fetchedSuggestions = fetchSuggestionsBasedOnInput_Cliente(value);
    setSuggestions_Cliente(fetchedSuggestions);
  }}
  onSuggestionsClearRequested={() => {
    setSuggestions_Cliente([]);
  }}
  getSuggestionValue={getSuggestionValue_Cliente}
  renderSuggestion={renderSuggestion_Cliente}
  renderInputComponent={renderInputComponent_Cliente}

  onSuggestionSelected={(event, { suggestion, method }) => {
    // Call handleClienteVinculadoChange with the selected client's ID
    if (method === 'click') {
        handleClienteResponsavelChange({ target: { value: suggestion.ID } });
        setIsClienteSelected(true);
    }
  }}
/>
</AutosuggestWrapper>
</InputContainer>


<ButtonContainer>
    <ActionButton onClick={handleSubmit}>
        {editingProcesso ? 'Atualizar Processo' : 'Salvar como Novo Processo'}
    </ActionButton>
  
    
    <ActionButton onClick={handleCancelEdit} style={{ display: editingProcesso ? 'block' : 'block' }}>
    {editingProcesso ? 'Cancelar Edição' : 'Cancelar Criação'}
</ActionButton>
</ButtonContainer>

    </FormContainer>
)}


{HistoricoVisible && (

<HorizontalFormContainer showCrumble >

<Table>  
  <TableHeader> 
    <TableTopRow>
    <TableHeaderCell transparent textAlign="center" colSpan={2}>
        
          <DescricaoLabel>Processo: {Numero_Processo} <br /> {NomeClienteProcesso}</DescricaoLabel>
       
      </TableHeaderCell>

      <TableHeaderCell transparent>
      <div>
      {errorCNJ && <div>Error: {errorCNJ}</div>}
      {responseDataCNJ && (
        <div>

            <TableHeaderCell transparent>
            <DescricaoLabel>Tribunal Atual: <br></br>
            {responseDataCNJ?.hits?.hits?.[0]?._source?.tribunal ?? ''}
            </DescricaoLabel>
            </TableHeaderCell>

          <TableHeaderCell transparent>
          <DescricaoLabel> Julgador Atual: <br></br>
          {responseDataCNJ?.hits?.hits?.[0]?._source?.orgaoJulgador?.nome ?? ''}
         </DescricaoLabel>

          </TableHeaderCell>

          <TableHeaderCell transparent>
          <DescricaoLabel>Classe Atual: <br></br>
          {responseDataCNJ?.hits?.hits?.[0]?._source?.classe?.nome ?? ''}
          </DescricaoLabel>

          </TableHeaderCell>

          <TableHeaderCell transparent>
          <DescricaoLabel>Assuntos: <br></br>
          {responseDataCNJ?.hits?.hits?.[0]?._source?.assuntos?.map(assunto => assunto?.nome).filter(nome => nome).join(' - ') ?? ''}
          </DescricaoLabel>

          </TableHeaderCell>

         
        </div>
        
      )}
    </div>
      </TableHeaderCell>


    </TableTopRow>
  </TableHeader>

  <TableBody>
    <TableTopRow>
      <TableCell>
          <InputContainer> 
            <TextoGrande ref={nomeInputRef}
            value={Historico}
            onChange={(e) => setHistorico(e.target.value)}
            placeholder=""
           />
          </InputContainer>
      </TableCell>

      {responseDataCNJ && (
      <TableCell colspan="2">
          <DescricaoLabel> Andamentos </DescricaoLabel>
          <ListContainer>
          <ListComponent
  items={responseDataCNJ.hits.hits
    .slice() // Faz uma cópia para não modificar o array original
    .flatMap((hit) => {
      return (hit._source.movimentos || [])
        .slice() // Faz uma cópia dos movimentos para não modificar o array original
        .reverse() // Inverte a ordem dos movimentos
        .map((movimento) => {
          const nomeMovimento = movimento?.nome ?? '';
          const complementosTabelados = movimento?.complementosTabelados || [];
          const complementos = complementosTabelados.map((complemento) => complemento?.nome || '').join(', ');

          const dataHora = new Date(movimento?.dataHora);
          const dia = String(dataHora.getDate()).padStart(2, '0');
          const mes = String(dataHora.getMonth() + 1).padStart(2, '0'); // Mês é baseado em zero, então adicionamos 1
          const ano = dataHora.getFullYear();

          return `${hit._source.grau} - ${nomeMovimento} - ${complementos} - ${dia}/${mes}/${ano}`;
        });
    })}
/>
</ListContainer>



          
          </TableCell>
      )}

{/*
      <TableCell>
      <Table>                     
    <TableBody>
        {TarefasProcesso
            .sort((a, b) => a.TAREFA_CONCLUIDA - b.TAREFA_CONCLUIDA)
            .map((tarefa) => (
                <TableRow key={tarefa.ID}>
                    <TableCell fontSize="12px" style={{textDecoration: tarefa.TAREFA_CONCLUIDA === 1 ? 'line-through' : 'none'}}>{tarefa.DESCRICAO}</TableCell> 
                    <TableCell fontSize="12px" style={{textDecoration: tarefa.TAREFA_CONCLUIDA === 1 ? 'line-through' : 'none'}}>{tarefa.PRAZO_FATAL}</TableCell>                 
                    <TableCell fontSize="12px" style={{textDecoration: tarefa.TAREFA_CONCLUIDA === 1 ? 'line-through' : 'none'}}>{tarefa.NOME_ADVOGADO}</TableCell>
                    <TableCell fontSize="12px" style={{textDecoration: tarefa.TAREFA_CONCLUIDA === 1 ? 'line-through' : 'none'}} onClick={() => HandleConcluirTarefa(tarefa.ID, tarefa.TAREFA_CONCLUIDA, tarefa.ID_PROCESSOVINCULADO)}>{tarefa.TAREFA_CONCLUIDA === 1 ? 'Concluída' : 'Clique para Concluir'}</TableCell>
             
                </TableRow>
            ))}
    </TableBody>
</Table>
      </TableCell>

          */}



  
  {/*  
      <TableCell>
      <SelectContainer>
        <InputLabel>Fase Processual</InputLabel>
        <Select value={ID_faseprocessual} onChange={handleFaseProcesssualChange}>          
          {fases.map(fase => (
            <option key={fase.ID} value={fase.ID}>
              {fase.descricao}
            </option>
          ))}
        </Select>
      </SelectContainer>
        </TableCell>
        
        */}

    </TableTopRow>

   

    <TableTopRow>
      <TableCell textAlign="center" colSpan={5}>
      

        <ButtonContainer>
        <Checkbox
    checked={tarefafazer === 1} // Verifica se tarefafazer é "1"
    onChange={() => setTarefafazer(tarefafazer === 1 ? 0 : 1)} // Inverte o valor de tarefafazer
    label="Exige Atenção"
  /> &nbsp;&nbsp;
        <ActionButton onClick={handleSubmit}>
        {editingProcesso ? 'Atualizar Processo' : 'Salvar como Novo Processo'}
    </ActionButton>
          <ActionButton onClick={handleCancelEdit} style={{ display: editingProcesso ? 'block' : 'block' }}>
          {editingProcesso ? 'Fechar Sem Motificiar' : 'Fechar Detalhes'}
          </ActionButton>
        </ButtonContainer>
      
        
      </TableCell>
    </TableTopRow>
  </TableBody>
</Table>

</HorizontalFormContainer>
)}




{dativo && HistoricoVisible && (
// aqui vai ficar o relatório de pagamentos dativos

<HorizontalFormContainer showCrumble >

<Table>  
  <TableHeader> 
    <TableTopRow>
    <TableHeaderCell transparent textAlign="center" colSpan={2}>
        
          <DescricaoLabel>Relação de Depósitos SEFAZ </DescricaoLabel>
       
      </TableHeaderCell>
    </TableTopRow>
  
 

 
        {resultados.map((resultado, index) => (
          <TableTopRow>  
          <TableHeaderCell transparent style={{ color: '#FFFFFF' }}qu textAlign="center" colSpan={2} key={index}>
          {resultado.data_lista_dativos} - {resultado.linha_pdf}
        </TableHeaderCell>
        </TableTopRow>
        ))}
       
      

</TableHeader>
      </Table>
</HorizontalFormContainer>
)}


</AppContainer>
    );
};

export default ProcessoPage;
