import React, { useState } from 'react';
import styled from 'styled-components';

const ConfirmationDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
`;

const ConfirmationMessage = styled.p`
  margin-bottom: 10px;
`;

const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.button`
  background-color: #f00;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: #ccc;
  color: #000;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
`;

const ConfirmationButton = ({ onConfirm, buttonText, confirmationMessage }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleButtonClick = () => {
    setShowConfirmation(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleConfirm = () => {
    setShowConfirmation(false);
    onConfirm(); // Chama a função passada como onConfirm prop
  };

  return (
    <>
      <ActionButton onClick={handleButtonClick}>{buttonText}</ActionButton>
      {showConfirmation && (
        <ConfirmationDialog>
          <ConfirmationMessage>{confirmationMessage}</ConfirmationMessage>
          <ConfirmationButtons>
            <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
            <ActionButton onClick={handleConfirm}>Confirmar</ActionButton>
          </ConfirmationButtons>
        </ConfirmationDialog>
      )}
    </>
  );
};

export default ConfirmationButton;
