// styles.js
import styled, { keyframes } from 'styled-components'; // Import keyframes here

import { Link } from 'react-router-dom';

export const CrumbleAnimation = keyframes`
  0% {
    transform: translate(0, 0) scale(0.2);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 50px) scale(1);
    opacity: 1;
  }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    animation: ${props => props.showCrumble && CrumbleAnimation} 0.4s forwards;
    border: ${props => props.showBorder ? '1px solid black' : 'none'};

`;

export const HorizontalFormContainer = styled.div`
    display: flex;
    flex-direction: row; /* Alterado para row para dispor horizontalmente */
    align-items: center;
    width: 80%;
    margin: 0 auto;
    animation: ${props => props.showCrumble && CrumbleAnimation} 0.4s forwards;
    border: ${props => props.showBorder ? '1px solid black' : 'none'};
`;

export const TableContainer = styled.div`
    width: 100%;
    margin: 0 auto;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ccc; /* Adicione borda à tabela */
`;

export const TableHeader = styled.thead``;

export const TableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    font-weight: bold;
    background-color: ${props => props.transparent ? 'transparent' : 'gray'};
`;


export const TableHeaderButton = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    background-color: transparent;
    border: 0; /* Remover a borda */
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }
    &:nth-child(odd) {
        background-color: #a2a2f2;
    }
`;

export const TableTopRow = styled.tr`
    &:nth-child(even) {
        background-color: transparent;
    }
    &:nth-child(odd) {
        background-color: transparent;
    }

    /* Apply colspan if the prop is provided */
    ${props => props.colspan && `
        > th {
            text-align: center;
            colspan: ${props.colspan};
        }
    `}
`;

 

export const TableCell = styled.td`
  padding: 10px;
  font-size: ${props => props.fontSize || 'inherit'};

  /* Alinhe os botões da coluna "Ações" */
  ${props => props.isActionsColumn && `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;



export const ActionButton = styled.button`
    padding: 5px 8px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-right: 10px;
    &:last-child {
        margin-left: 5px;
    }
`;

export const CreateButton = styled.button`
    background-color: #EB9B00;
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    /* Defina o tamanho do botão explicitamente */
    width: 100%;
    height: 35px;
`;


export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center; /* Centraliza os elementos horizontalmente */
`;

export const InputLabel = styled.label`
    display: inline-block;
    width: 100%;
    margin-right: 10px;
    font-weight: bold;
    color: #FFF; /* Cor da fonte branca */
`;

export const DescricaoLabel = styled.label`
    display: inline-block;
    width: 100%;
    margin-right: 1px;
    font-weight: bold;
    color: #FFF; /* Cor da fonte branca */
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column; /* Alterado para column */
  align-items: flex-start; /* Alterado para flex-start */
  margin-bottom: 10px;
`;

export const SelectLabel = styled.label`
  margin-bottom: 5px; /* Adicionado espaço inferior */
  font-weight: bold;
  color: #000; /* Cor da fonte preta */
`;

export const Select = styled.select`
  padding: 10px 20px;
  border: 1px solid #007bff; /* Cor da borda azul por padrão */
  background-color: #007bff; /* Fundo azul por padrão */
  border-radius: 20px;
  width: 400px; /* Mantido igual ao Input */
  color: #FFF; /* Cor da fonte branca */
  font-size: 14px;
  margin-bottom: 10px;

  &:focus {
    outline: none;
    background-color: #007bff; /* Mantém o fundo azul ao focar */
    border-color: #007bff; /* Mantém a borda azul ao focar */
  }
`;

export const Linkdogrid = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    /* Defina os estilos de hover, se desejar */
  }
`;