import styled from "styled-components"


export const Input = styled.input.attrs(props => ({
    maxLength: props.maxLength || 60 // Default maxLength is set to 60 if not provided
}))`
    border: 1px solid ${props => (props.isFocused ? '#FFF' : '#007bff')};
    background: transparent;
    padding: 10px 20px;
    border-radius: 20px;
    width: 400px;
    color: #FFF;
    font-size: 14px;
    margin-bottom: 10px;
    

    &::placeholder {
        color: #FFF;
        font-size: 14px;
    }

    &:focus {
        border-color: ${props => (props.isFocused ? '#007bff' : '#FFF')};
        outline: none;
    }
`;

export const TextoGrande = styled.textarea.attrs(props => ({
    maxLength: props.maxLength || 30000 // Default maxLength is set to 30000 if not provided
}))`
    border: 1px solid ${props => (props.isFocused ? '#FFF' : '#007bff')};
    background: transparent;
    padding: 10px;
    border-radius: 10px;
    width: 400px;
    height: 200px; /* Set the initial height */
    color: #FFF;
    font-size: 14px;
    margin-bottom: 10px;
    resize: both; /* Allow resizing in both directions */
    overflow: auto; /* Enable scrollbars */
    

    &::placeholder {
        color: #FFF;
        font-size: 14px;
    }

    &:focus {
        border-color: ${props => (props.isFocused ? '#007bff' : '#FFF')};
        outline: none;
    }
`;

export const InputPesquisa = styled.input.attrs(props => ({
    maxLength: props.maxLength || 60 // Default maxLength is set to 60 if not provided
}))`
    border: 1px solid ${props => (props.isFocused ? '#FFF' : '#007bff')};
    background: transparent;
    padding: 10px 20px;
    border-radius: 20px;
    width: 500px;
    color: #FFF;
    font-size: 14px;
     
    

    &::placeholder {
        color: #FFF;
        font-size: 14px;
    }

    &:focus {
        border-color: ${props => (props.isFocused ? '#007bff' : '#FFF')};
        outline: none;
    }
`;

export const AutosuggestWrapper = styled.div`
  input {
    border: 1px solid ${props => props.isSelected ? 'initial' : 'red'};
    /* Adicione outros estilos conforme necessário */
  }
`;


export default Input;