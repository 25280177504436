import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [IDAdvogado, setIDAdvogado] = useState('');
  const [NOMEAdvogado, setNomeAdvogado] = useState('');
  const [OABAdvogado, setOABAdvogado] = useState('');
  const [EstadoOABAdvogado, setEstadoOABAdvogado] = useState('');
  const [EmailAdvogado, setEmailAdvogado] = useState('');
  const [AccessLevelAdvogado, setAccessLevelAdvogado] = useState('');
  const [ID_UltimoClienteAlterado, setID_UltimoClienteAlterado] = useState('');

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, NOMEAdvogado, setNomeAdvogado, IDAdvogado, setIDAdvogado, OABAdvogado, setOABAdvogado, EstadoOABAdvogado, setEstadoOABAdvogado, EmailAdvogado, setEmailAdvogado, AccessLevelAdvogado, setAccessLevelAdvogado, ID_UltimoClienteAlterado}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
