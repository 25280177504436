import React, {useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import { Input, InputPesquisa} from '../componentes/Input';
import { Titulo } from '../componentes/Titulo';
import AppContainer from '../componentes/AppContainer';
import serverUrl from '../apiconfig'; // Importando o serverUrl
import { useAuth } from '../AuthContext';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import SuggestionItem from '../componentes/AutoSuggest/styled-suggests';

import {
    FormContainer,
    TableContainer,
    Table,
    TableHeader,
    TableHeaderCell,
    TableHeaderButton,
    TableBody,
    TableRow,
    TableCell,
    ActionButton,
    CreateButton,
    ButtonContainer,
    InputContainer,
    InputLabel,
    SelectContainer,
    Select,
    Linkdogrid,
    TableTopRow,
  } from '../componentes/GridList/gridList'; // Importando os estilos do arquivo styles.js

  // Função para recuperar o valor de um cookie
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const TarefaPage = ({ isAuthenticated }) => {
    const [clientes, setClientes] = useState([]);
    const [tarefas, setTarefas] = useState([]);
    const [editingTarefa, setEditingTarefa] = useState(null);
    const [formVisible, setFormVisible] = useState(false);
    const [advogados, setAdvogados] = useState([]);
    const { IDAdvogado } = useAuth();
    const nomeInputRef = useRef(null);
    const [filterConcluido, setFilterConcluido] = useState("");
    const [GridVisible, setGridVisible] = useState(true);
    const [descricao, setDescricao] = useState('');
    const [id_processovinculado, setID_processovinculado] = useState(false);
    const [id_clientevinculado, setID_clientevinculado] = useState(false);   
    const [id_advogadoresponsavel, setID_advogadoresponsavel] = useState(false);
    const [prazo_fatal, setPrazo_fatal] = useState(false);
    const [tarefa_concluida, setTarefa_concluida] = useState(false);
    const [processos, setProcessos] = useState('');
    const [searchValue_Cliente, setSearchValue_Cliente] = useState(''); // Input value
    const [suggestions_Cliente, setSuggestions_Cliente] = useState([]); // Suggestions
    const [searchValue_Processo, setSearchValue_Processo] = useState(''); // Input value
    const [suggestions_Processo, setSuggestions_Processo] = useState([]); // Suggestions

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filtroParam = queryParams.get('filtro');
    const [filterText, setFilterText] = useState("");
    
    const apiUrl = `${serverUrl}/tarefas`; // Combinando o serverUrl com a rota específica
    const apiUrlAdvogados = `${serverUrl}/advogados`; // Combinando o serverUrl com a rota específica
    const apiUrlProcessos = `${serverUrl}/processos`; // Combinando o serverUrl com a rota específica
    const apiUrlClientes = `${serverUrl}/clientes`; // Combinando o serverUrl com a rota específica

    const fetchTarefas = useCallback(async () => {
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setTarefas(response.data);
            console.log("variavel tarefa:", response.data);
        } catch (error) {
            console.error(error);
        }
    },[apiUrl]); 

    const handleEditClick = (tarefa) => {
        setEditingTarefa(tarefa);
        setGridVisible(false);
        setFormVisible(true); // Mostra o formulário ao editar
        
        setDescricao(tarefa.DESCRICAO);
        setID_processovinculado(tarefa.ID_PROCESSOVINCULADO);
        setID_advogadoresponsavel(tarefa.ID_ADVOGADORESPONSAVEL);
        setID_clientevinculado(tarefa.ID_CLIENTEVINCULADO);
        setPrazo_fatal(tarefa.PRAZO_FATAL);
        setTarefa_concluida(tarefa.TAREFA_CONCLUIDA);
        if(tarefa.nome_completo_cliente)setSearchValue_Cliente(tarefa.nome_completo_cliente);
        if(tarefa.Numero_Processo)setSearchValue_Processo(tarefa.Numero_Processo);

    };

    const handleCancelEdit = () => {
        clearForm();
        setFormVisible(false); // Esconde o formulário após salvar/atualizar
        setGridVisible(true);

    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${apiUrl}/${id}`, {
                headers: {
                    Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
            });
            fetchTarefas();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const prazoFatalFormatado = converterDataFormato(prazo_fatal);
            const tarefaData = {
                DESCRICAO: descricao,
                ID_PROCESSOVINCULADO: id_processovinculado,
                ID_CLIENTEVINCULADO: id_clientevinculado,
                ID_ADVOGADORESPONSAVEL: id_advogadoresponsavel,             
                PRAZO_FATAL: prazoFatalFormatado,
                TAREFA_CONCLUIDA: tarefa_concluida,               
            };

            console.log("o id do advogado responsavel jogado lá foi:", id_advogadoresponsavel);
     
            if (editingTarefa) {
                // Atualizar tarefa
                await axios.put(`${apiUrl}/${editingTarefa.ID}`, tarefaData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            } else {
                // Criar tarefa                
                await axios.post(apiUrl, tarefaData, {
                    headers: {
                        Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                    }
                });
            }
    
            clearForm();
            fetchTarefas();            
            setFormVisible(false); // Esconde o formulário após salvar/atualizar
            setGridVisible(true);

        } catch (error) {
            console.error(error);
        }
    };

    // Função para converter data de 'DD/MM/AAAA' para 'AAAA-MM-DD'
function converterDataFormato(data) {
    const partesData = data.split('/'); // Divide a data em partes pelo caractere '/'
    if (partesData.length === 3) {
      const dia = partesData[0];
      const mes = partesData[1];
      const ano = partesData[2];
      
      // Verifique se as partes da data são numéricas
      if (!isNaN(dia) && !isNaN(mes) && !isNaN(ano)) {
        // Certifique-se de que as partes da data tenham dois dígitos
        const diaFormatado = dia.padStart(2, '0');
        const mesFormatado = mes.padStart(2, '0');
  
        // Formate a data no formato 'AAAA-MM-DD'
        const dataFormatada = `${ano}-${mesFormatado}-${diaFormatado}`;
        
        return dataFormatada;
      }
    }
  
    // Se a entrada não for válida, lance um erro
    throw new Error('Data de entrada inválida: ' + data);
  }

  function formatarData(input) {
    // Remove caracteres não numéricos
    let inputLimpo = input.replace(/\D/g, '');
  
    // Adiciona as barras de acordo com a posição do cursor
    if (inputLimpo.length > 2) {
      inputLimpo = inputLimpo.substring(0, 2) + '/' + inputLimpo.substring(2);
    }
    if (inputLimpo.length > 5) {
      inputLimpo = inputLimpo.substring(0, 5) + '/' + inputLimpo.substring(5, 9);
    }

    setPrazo_fatal(inputLimpo);
}

    const handleCreateButtonClick = () => {
        clearForm();
        setFormVisible(true);
        setTarefa_concluida(false);
        setGridVisible(false);
  };
    

    const handleTarefaConcluidaChange = (e) => {
        setTarefa_concluida(e.target.value === 'true'); // Converte a string 'true' ou 'false' para booleano
      };
    

    const clearForm = () => {
        setEditingTarefa(null);
        setDescricao('');
        setID_processovinculado('');
        setID_advogadoresponsavel('');
        setID_clientevinculado('');
        setPrazo_fatal('');
        setTarefa_concluida(false);
    };

    const fetchAdvogados = useCallback(async () => {
        try {
            const response = await axios.get(apiUrlAdvogados, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setAdvogados(response.data);
        } catch (error) {
            console.error(error);
        }
    },[apiUrlAdvogados]);

    const fetchClientes = useCallback(async () => {
        try {
            const response = await axios.get(apiUrlClientes, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setClientes(response.data);
        } catch (error) {
            console.error(error);
        }
    },[apiUrlClientes]);


    const fetchProcessos = useCallback(async () => {
        try {
            const response = await axios.get(apiUrlProcessos, {
                headers: {
                  Authorization: `Bearer ${getCookie('token')}` // Adicione o token no cabeçalho
                }
              });
            setProcessos(response.data);
        } catch (error) {
            console.error(error);
        }
    },[apiUrlProcessos]);

    const handleAdvogadoResponsavelChange = e => {
        const selectedAdvogadoId = e.target.value;
        setID_advogadoresponsavel(selectedAdvogadoId);
      };   

      const handleProcessoVinculadoChange = e => {
        const selectedProcessoVinculadoID = e.target.value;
        setID_processovinculado(selectedProcessoVinculadoID);
      };   

      const handleClienteVinculadoChange = e => {
        const selectedClienteVinculadoID = e.target.value;
        setID_clientevinculado(selectedClienteVinculadoID);
      };   

      
     
      const filteredTarefas = tarefas.filter((tarefa) => {
        const filterTextLower = filterText ? filterText.toLowerCase() : '';
    
        const condicao1 = (
            (tarefa.Numero_Processo && tarefa.Numero_Processo.toLowerCase().includes(filterTextLower)) ||
            (tarefa.Descricao && tarefa.Descricao.toLowerCase().includes(filterTextLower)) ||
            (tarefa.primeiro_nome_advogado && tarefa.primeiro_nome_advogado.toLowerCase().includes(filterTextLower)) ||
            (tarefa.nome_completo_cliente && tarefa.nome_completo_cliente.toLowerCase().includes(filterTextLower))
        );
    
        const condicao2 = (filterConcluido === "1" ? tarefa.TAREFA_CONCLUIDA !== 1 : true);
    
        return condicao1 && condicao2;
    });
    
    
    

    //***************Suggestion Clientes*********************************** */

    // Function to handle input value change
const handleSearchChange_Cliente = (event, { newValue }) => {
  setSearchValue_Cliente(newValue);
};

// Function to fetch suggestions based on input
const fetchSuggestionsBasedOnInput_Cliente = (inputValue) => {
  // Filter your 'clientes' array based on the inputValue
  const filteredClientes = clientes.filter((cliente) =>
    cliente.NOME.toLowerCase().includes(inputValue.toLowerCase())
  );

  return filteredClientes;
};

// Function to get the suggestion value to display
const getSuggestionValue_Cliente = (suggestion) => suggestion.NOME;

// Function to render each suggestion item
const renderSuggestion_Cliente = (suggestion) => (
  <SuggestionItem>{suggestion.NOME}</SuggestionItem>
);

const renderInputComponent_Cliente = inputProps => (
  <div>
    <Input {...inputProps} />
  </div>
);
    

//***************Suggestion Processos*********************************** */

    // Function to handle input value change
    const handleSearchChange_Processo = (event, { newValue }) => {
      setSearchValue_Processo(newValue);
    };
    
    // Function to fetch suggestions based on input
    const fetchSuggestionsBasedOnInput_Processo = (inputValue) => {
      // Filter your 'processos' array based on the inputValue
      const filteredProcessos = processos.filter((processo) =>
        processo.Numero_Processo.toLowerCase().includes(inputValue.toLowerCase())
      );
    
      return filteredProcessos;
    };
    
    // Function to get the suggestion value to display
    const getSuggestionValue_Processo = (suggestion) => suggestion.Numero_Processo;
    
    // Function to render each suggestion item
    const renderSuggestion_Processo = (suggestion) => (
      <SuggestionItem>{suggestion.Numero_Processo}</SuggestionItem>
    );
    
    const renderInputComponent_Processo = inputProps => (
      <div>
        <Input {...inputProps} />
      </div>
    );
    

      useEffect(() => {
        fetchTarefas();
        fetchAdvogados();
        fetchProcessos();
        fetchClientes();
        setFilterConcluido("1");
        setFilterText(filtroParam || '');
        
        // Verificar se é uma situação de edição e pré-selecionar valores do contexto
        if (!editingTarefa) {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Os meses são base 0, então somamos 1.
            const year = today.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;
            
            setID_advogadoresponsavel(IDAdvogado);
            setPrazo_fatal(formattedDate);
        }
        if (formVisible && nomeInputRef.current) {
            nomeInputRef.current.focus();
          }
      }, [filtroParam, editingTarefa, IDAdvogado, formVisible, fetchAdvogados, fetchClientes, fetchProcessos, fetchTarefas]);

    return (
        <AppContainer>
    <Header isAuthenticated={isAuthenticated} />
    
    
    <TableContainer>
    <Titulo tamanhoFonte="24px" alinhamento="center" cor="white">Gerenciamento de Tarefas</Titulo>

    {GridVisible && (

        <Table>
        <TableHeader> 
            
        <TableTopRow> <TableHeaderCell transparent colSpan={1}>
        <TableHeaderButton> <CreateButton onClick={() => handleCreateButtonClick()}>
    Criar Nova
</CreateButton></TableHeaderButton>

</TableHeaderCell>

<TableHeaderCell  transparent colSpan={3}>


<TableHeaderButton > 
        <InputPesquisa 
        type="text"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        placeholder="Digite qualquer coisa para filtrar"
        />
         </TableHeaderButton>  </TableHeaderCell>
        
         <TableHeaderCell  transparent colSpan={3}>
         <TableHeaderButton > 

<Select value={filterConcluido} onChange={(e) => setFilterConcluido(e.target.value)}>     
        <option value="1">Somente Tarefas em Aberto</option>     
        <option value="0">Todas as Tarefas</option> 
        </Select>
         </TableHeaderButton>
         </TableHeaderCell>

               </TableTopRow>
            </TableHeader>
            
            <TableHeader>
                <TableRow>
                    
                    <TableHeaderCell>Descricao da Tarefa</TableHeaderCell>  
                    <TableHeaderCell>Prazo Fatal</TableHeaderCell>  
                    <TableHeaderCell>Cliente</TableHeaderCell> 
                    <TableHeaderCell>Processo</TableHeaderCell>      
                    <TableHeaderCell>Adv. Responsavel</TableHeaderCell>
                    <TableHeaderCell>Concluida</TableHeaderCell>
                    <TableHeaderCell isActionsColumn>Ações</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {filteredTarefas.map((tarefa) => (
                    <TableRow key={tarefa.ID}>
                        
                        <TableCell><Linkdogrid to={`/processo?filtro=${tarefa.DESCRICAO}`}>{tarefa.DESCRICAO}</Linkdogrid></TableCell> 
                        <TableCell>{tarefa.PRAZO_FATAL}</TableCell>
                        <TableCell><Linkdogrid to={`/cliente?filtro=${tarefa.nome_completo_cliente}`}>{tarefa.nome_completo_cliente}</Linkdogrid></TableCell>
                        <TableCell><Linkdogrid to={`/processo?filtro=${tarefa.NUM_PROCESSO}`}>{tarefa.NUM_PROCESSO}</Linkdogrid></TableCell>
                        <TableCell>{tarefa.NOME_ADVOGADO}</TableCell>
                        <TableCell>{tarefa.TAREFA_CONCLUIDA === 1 ? 'SIM' : 'NAO'}</TableCell>

                        <TableCell isActionsColumn>
                            <ActionButton onClick={() => handleEditClick(tarefa)}>Editar</ActionButton>                           
                            <ActionButton onClick={() => handleDeleteClick(tarefa.ID)}>Excluir</ActionButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>

         )}
    </TableContainer>

   

    <br></br>
    {formVisible && (

<FormContainer showCrumble>
<InputContainer>
<InputLabel>Descricao da Tarefa:</InputLabel>
<Input ref={nomeInputRef}
        maxLength={50} // Set the maximum length to 30 characters
        value={descricao}
        onChange={(e) => setDescricao(e.target.value)}
        placeholder="Nome da Tarefa"
    />
</InputContainer>

<InputContainer>
<InputLabel>Prazo Fatal:</InputLabel>
<Input        
        value={prazo_fatal}
        onChange={(e) => formatarData(e.target.value)}
        placeholder="DD/MM/AAAA"
    />
</InputContainer>

<InputContainer>
<InputLabel>Cliente:</InputLabel>
      
      <Autosuggest
  inputProps={{
    placeholder: 'Digite o nome do cliente, se houver...',
    value: searchValue_Cliente,
    onChange: handleSearchChange_Cliente,
  }}
  suggestions={suggestions_Cliente}
  onSuggestionsFetchRequested={({ value }) => {
    const fetchedSuggestions = fetchSuggestionsBasedOnInput_Cliente(value);
    setSuggestions_Cliente(fetchedSuggestions);
  }}
  onSuggestionsClearRequested={() => {
    setSuggestions_Cliente([]);
  }}
  getSuggestionValue={getSuggestionValue_Cliente}
  renderSuggestion={renderSuggestion_Cliente}
  renderInputComponent={renderInputComponent_Cliente}

  onSuggestionSelected={(event, { suggestion, method }) => {
    // Call handleClienteVinculadoChange with the selected client's ID
    if (method === 'click') {
      handleClienteVinculadoChange({ target: { value: suggestion.ID } });
    }
  }}
/></InputContainer>

<InputContainer>
<InputLabel>Processo:</InputLabel>
      
      <Autosuggest
  inputProps={{
    placeholder: 'Digite o número do processo, se houver...',
    value: searchValue_Processo,
    onChange: handleSearchChange_Processo,
  }}
  suggestions={suggestions_Processo}
  onSuggestionsFetchRequested={({ value }) => {
    const fetchedSuggestions = fetchSuggestionsBasedOnInput_Processo(value);
    setSuggestions_Processo(fetchedSuggestions);
  }}
  onSuggestionsClearRequested={() => {
    setSuggestions_Processo([]);
  }}
  getSuggestionValue={getSuggestionValue_Processo}
  renderSuggestion={renderSuggestion_Processo}
  renderInputComponent={renderInputComponent_Processo}

  onSuggestionSelected={(event, { suggestion, method }) => {
    // Call handleProcessoVinculadoChange with the selected client's ID
    if (method === 'click') {
      handleProcessoVinculadoChange({ target: { value: suggestion.ID } });
    }
  }}
/></InputContainer>




{/*
<SelectContainer>
        <InputLabel>Processo:</InputLabel>
        <Select value={id_processovinculado} onChange={handleProcessoVinculadoChange}>     
        <option value="">Tarefa sem Processo Vinculado</option>     
          {processos.map(processo => (
            <option key={processo.ID} value={processo.ID}>
              {processo.Numero_Processo}
            </option>
          ))}
        </Select>
      </SelectContainer>
*/}


<SelectContainer>
        <InputLabel>Adv. Responsavel:</InputLabel>
        <Select value={id_advogadoresponsavel} onChange={handleAdvogadoResponsavelChange}>          
          {advogados.map(advogado => (
            <option key={advogado.ID} value={advogado.ID}>
              {advogado.NOME}
            </option>
          ))}
        </Select>
      </SelectContainer>

<SelectContainer>
  <InputLabel>Concluida:</InputLabel>
  <Select value={tarefa_concluida ? 'true' : 'false'} onChange={handleTarefaConcluidaChange}>
    <option value="false">Em Aberto</option>
    <option value="true">Concluida</option>
  </Select>
</SelectContainer>




<ButtonContainer>
    <ActionButton onClick={handleSubmit}>
        {editingTarefa ? 'Atualizar Tarefa' : 'Salvar como Nova Tarefa'}
    </ActionButton>
  
    
    <ActionButton onClick={handleCancelEdit} style={{ display: editingTarefa ? 'block' : 'block' }}>
    {editingTarefa ? 'Cancelar Edição' : 'Cancelar Criação'}
</ActionButton>
</ButtonContainer>

    </FormContainer>
)}
</AppContainer>
    );
};

export default TarefaPage;
