import React from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
   
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  color: white;
  cursor: pointer;
`;

export const Checkbox = ({ checked, onChange, label }) => (
  <CheckboxWrapper>
    <CheckboxLabel>
      <CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {label}
    </CheckboxLabel>
  </CheckboxWrapper>
);

export default Checkbox;
