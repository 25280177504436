import HomePage from './pages/homePage'; // Importe suas páginas
import AboutPage from './pages/aboutPage';
import DativosPage from './pages/dativosPage';
import LoginPage from './pages/loginPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdvogadoPage from './pages/advogadoPage';
import ClientePage from './pages/clientePage';
import ProcessoPage from './pages/processoPage';
import TarefaPage from './pages/tarefaPage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/dativos" element={<DativosPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/advogado" element={<AdvogadoPage />} />
        <Route path="/cliente" element={<ClientePage />} /> 
        <Route path="/processo" element={<ProcessoPage />} /> 
        <Route path="/tarefa" element={<TarefaPage />} /> 


        {/* Mais rotas podem ser adicionadas aqui */}
      </Routes>
    </Router>
  );
}

export default App;
