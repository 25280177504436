import logo from '../../imagens/logo.svg'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const OpcaoBase = styled.li`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 0 5px;
    cursor: pointer;
    min-width: 120px;
`;

const OpcaoLink = styled(Link)`
    ${OpcaoBase};
    text-decoration: none !important;
    color: inherit;
    
    &:hover {
        text-decoration: underline;
        color: green; /* Substitua pela cor desejada */
    }
`;

const LogoContainer = styled.div`
    display: flex;
    font-size: 30px; 
 `
 const LogoImage = styled.img`
    margin-right: 10px;
`

function Logo () {
    return(
        <LogoContainer>
            <LogoImage
                src={logo}
                alt='logo'
            ></LogoImage>
           <OpcaoLink to="/"> <p><strong>Juris</strong>Flix</p></OpcaoLink> 
        </LogoContainer>
    )
}

export default Logo
