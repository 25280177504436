import perfil from '../../imagens/perfil.svg'
import sacola from '../../imagens/sacola.svg'
import styled from 'styled-components'
import { Link } from 'react-router-dom';


const Icone = styled.li`
    margin-right: 40px;
    width: 25px;
`
const Icones = styled.ul`
    display: flex;
    align-items: center;
`

//const icones = [perfil, sacola]
const icones = [perfil]

function IconesHeader() {
    return (
      <Icones>
        {icones.map((icone, index) => (
          <Link key={index} to={icone === perfil ? '/login' : ''}>
            <Icone>
              <img src={icone} alt={`Ícone ${index}`} />
            </Icone>
          </Link>
        ))}
      </Icones>
    );
  }

export default IconesHeader