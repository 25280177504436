import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const OpcaoBase = styled.li`
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 0 5px;
    cursor: pointer;
    min-width: 120px;
`;

const Opcao = styled(OpcaoBase)``;

const OpcaoLink = styled(Link)`
    ${OpcaoBase};
    text-decoration: none !important;
    color: inherit;
    
    &:hover {
        text-decoration: underline;
        color: green; /* Substitua pela cor desejada */
    }
`;

const Opcoes = styled.ul`
    display: flex;
`



function OpcoesHeader() {
  const { AccessLevelAdvogado } = useAuth();
  let textoOpcoes;

  if (AccessLevelAdvogado === 1) {
    textoOpcoes = ['TAREFAS', 'ADVOGADOS', 'CLIENTES', 'PROCESSOS', 'DATIVOS', 'FERRAMENTAS', 'SOBRE NÓS'];
  } else {
    textoOpcoes = ['CLIENTES', 'PROCESSOS', 'DATIVOS', 'SOBRE NÓS'];
  }

    return (
      <Opcoes>
        {textoOpcoes.map((texto) => (
          <Opcao key={texto}>
            {texto === 'SOBRE NÓS' ? (
              <OpcaoLink to="/about">{texto}</OpcaoLink>
            ):
            texto === 'CLIENTES' ? (
                <OpcaoLink to="/cliente">{texto}</OpcaoLink>
              )
            :
            texto === 'ADVOGADOS' ? (
                <OpcaoLink to="/advogado">{texto}</OpcaoLink>
              ) :              
            texto === 'TAREFAS' ? (
              <OpcaoLink to="/tarefa">{texto}</OpcaoLink>
            ) :
              texto === 'PROCESSOS' ? (
                <OpcaoLink to="/processo">{texto}</OpcaoLink>
              ) :
            texto === 'DATIVOS' ? (
                <OpcaoLink to="/dativos">{texto}</OpcaoLink>
              ) :
               (
              <p>{texto}</p>
            )}
          </Opcao>
        ))}
      </Opcoes>
    );
  }

export default OpcoesHeader