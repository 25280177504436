import styled from 'styled-components'
import Header from '../componentes/Header';
import AppContainer from '../componentes/AppContainer';
import React, { useState } from 'react';
import axios from 'axios';
import serverUrl from '../apiconfig'; // Importando o serverUrl

const Titulo = styled.h2`
        color: lightblue;
        font-size: 36px;
        text-align: center;
        width: 100%;
`

const Conteudo = styled.p`
    color: #FFF;
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 20px 0;
`;

const aboutPage = ({ isAuthenticated }) => {
  

  return (
    <AppContainer>
     <Header isAuthenticated={isAuthenticated} />

     <Titulo tamanhoFonte="24px" alinhamento="center" cor="white">Desenvolvido por: marcosouza@outlook.com.br</Titulo>
    
    </AppContainer>
  );
};
export default aboutPage;
